const loadmorePlugin = (form, btn, svg, wrapper) => {
    if (form) {
        form.addEventListener('submit', async (e) => {
            e.preventDefault();
            e.stopPropagation();
    
            //button transformation
            svg.classList.remove('dn');
            btn.classList.add('active');
        
            //Creating FormData object
            const fData = new FormData(form);

            const offset = wrapper.querySelectorAll("[data-bookmark-item]").length;
            fData.append('offset', offset);

            const fAction = form.getAttribute('action');

            // Display the key/value pairs
            for(const pair of fData.entries()) {
                console.log(pair[0]+ ': '+ pair[1]);
            }
    
            //Sending form to server
            const fetchResponse = await fetch(fAction, {
                method: 'POST',
                body: fData
            });
    
            const result = await fetchResponse.text();
    
            //change loading svg to text
            svg.classList.add('dn');
            btn.classList.remove('active');
                
            if (fetchResponse.ok) {
                console.log(result);
                wrapper.innerHTML += result;
            } else {
                console.log(result);
                btn.classList.add("dn");
            }
        
        });
    }
}

export {loadmorePlugin}
