const topicBlock = () => {

    const topicBlockElement = document.querySelector('[data-topic-block]');

    //checking for info block and adding styles if he is in template
    if (document.getElementById('info-block')) {
        topicBlockElement.classList.add('up');
    }

}

export {topicBlock}