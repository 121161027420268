const infoBlock = () => {

    const block = document.getElementById('info-block');
    const closeBtn = document.querySelector('[data-info-block-close]');

    const footer = document.getElementById('footer');
    footer.classList.add('up');

    //close button logic
    closeBtn.addEventListener('click', () => {
        //close info block
        block.classList.add('dn');

        //deleting all up`s classes
        const ups = document.querySelectorAll('.up');
        for (const up of ups) {
            up.classList.remove('up');
        }

    });
}

export {infoBlock}