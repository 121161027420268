const cookiesModal = (block) => {

    const cancelBtn = block.querySelector('[data-cookies-modal-cancel]');
    const acceptBtn = block.querySelector('[data-cookies-modal-accept]');

    //checking for info block and adding styles if he is in template
    if (document.getElementById('info-block')) {
        block.classList.add('up');
    }

    //close modal function
    const closeModal = () => {
        block.classList.add('dn');
    }

    const openModal = () => {
        block.classList.remove('dn');
    }

    //local storage variables
    const consentPropertyName = 'cookies_enabled';

    const shouldShowModal = () => !localStorage.getItem(consentPropertyName);
    const saveToStorageTrue = () => localStorage.setItem(consentPropertyName, true);
    const saveToStorageFalse = () => localStorage.setItem(consentPropertyName, false);

    //delete all cookies
    if (localStorage.getItem(consentPropertyName) == 'false') {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        return;
    }

    //close modal logic
    window.addEventListener('load', () => {
        if (shouldShowModal()) {
            openModal();
        }
    });

    //cancel btn logic
    cancelBtn.addEventListener('click', () => {
        //saving property name and value to local storage
        saveToStorageFalse();
        closeModal();
    });

    //accept btn logic
    acceptBtn.addEventListener('click', () => {
        //saving property name and value to local storage
        saveToStorageTrue();
        closeModal();
    });

}

export {cookiesModal}