const headerSearch = () => {

    const form = document.getElementById('search-form');
    const input = document.getElementById('search-input');
    const mobileSearchOpenBtn = document.getElementById('mobile-search-open-btn');

    //open seach form logic
    const openSearchFormLogic = () => {
        //add focused styles to form
        form.classList.add('focused');
    }

    //close seach form logic
    const closeSearchFormLogic = () => {
        //remove focused styles from form
        form.classList.remove('focused');
    }

    //focus on input element if click was on form
    form.addEventListener('click', () => {
        if (!form.classList.contains('focused')) {
            input.focus();
        }
    });

    //close form block if clicked not on him
    window.addEventListener('click', (e) => {
        if (!e.target.closest('#search-form')) {
            closeSearchFormLogic();
        }
    });

    //input focus logic
    input.addEventListener('focus', () => {
        openSearchFormLogic();
    });

    //input blur logic
    input.addEventListener('blur', (e) => {
        if (!e.target.closest('#search-form')) {
            closeSearchFormLogic();
        }
    });

    //mobile search open btn logic
    mobileSearchOpenBtn.addEventListener('click', (e) => {
        e.stopPropagation();
        openSearchFormLogic();
    });
}

export {headerSearch}
