const locationList = (sections) => {

    for (const section of sections) {
        const sectionName = section.getAttribute('data-section');
        const btn = section.querySelector(`[data-location-btn="${sectionName}"]`);
        const list = section.querySelector(`[data-location-list="${sectionName}"]`);

        //open list logic
        const openList = () => {
            // btn.classList.add('active');
            list.classList.add('db');
            setTimeout(() => {
                list.classList.add('opened');
            }, 1);
        }

        //close list logic
        const closeList = () => {
            // btn.classList.remove('active');
            list.classList.remove('opened');
            setTimeout(() => {
                list.classList.remove('db');
            }, 300);
        }

        //location btn logic
        btn.addEventListener('click', () => {
            if (!list.classList.contains('db')) {
                openList();
            } else {
                closeList();
            }
        });

        //close list if clicked not on him
        window.addEventListener('click', (e) => {
            if (!e.target.closest(`[data-location-btn="${sectionName}"]`) && !e.target.closest(`[data-location-list="${sectionName}"]`) && !e.target.closest('.country__search')) {
                closeList();
            }
        });

        //country list ========================================================================================================================
        const openListBtn = list.querySelector(`[data-location-active-item="${sectionName}"]`);
        const countryList = list.querySelector('[data-country-list]');
        const countryItems = list.querySelectorAll('[data-country-item]');
        const countrySearchInput = section.querySelector('[data-country-search-input]');
        const countrySearch = section.querySelector('[data-country-search]');
        const countrySearchBtn = section.querySelector('[data-country-search-btn]');

        //open list logic
        const openListLogic = () => {
            countryList.classList.add('db');
            setTimeout(() => {
                countryList.classList.add('open');
            }, 1);
        }

        //close list logic
        const closeListLogic = () => {
            countryList.classList.remove('open');
            setTimeout(() => {
                countryList.classList.remove('db');
            }, 300);
        }

        //add click event for every contry item
        for (let countryItem of countryItems) {
            countryItem.addEventListener('click', () => {
                //disable others active element
                for (let countryI of countryItems) {
                    countryI.classList.remove('active');
                }

                //activate contry element
                countryItem.classList.add('active');

                setTimeout(() => {
                    //close list
                    closeListLogic();
                }, 100);
            });
        }

        //open list if clicked on block
        openListBtn.addEventListener('click', (e) => {
            if (countryList.classList.contains('db') && !e.target.closest('.country__search')) {
                closeListLogic();
            } else {
                openListLogic();
            }
        });

        //search ==============================================================================================================================

        //search logic
        const searchLogic = () => {
            let searchWord = countrySearchInput.value.toLowerCase();

            for (let countryItem of countryItems) {
                let countryText = countryItem.innerText.toLowerCase();

                if (countryText.indexOf(searchWord) !== -1) {
                    countryItem.classList.remove("hidden");
                } else {
                    countryItem.classList.add("hidden");
                }
            }
        }

        //focus on input if we clicked on search block
        countrySearch.addEventListener('click', () => {
            countrySearchInput.focus();
        });

        //country search button click logic
        countrySearchBtn.addEventListener('click', () => {
            searchLogic();
        });

        //add enter logic to input field
        countrySearchInput.addEventListener('keydown', (e) => {
            setTimeout(() => {searchLogic()}, 1);

            // if (e.keyCode == 13) {
            //     e.preventDefault();
            //     e.stopPropagation();
            //     searchLogic();
            // }
        });
    }

}

export {locationList}