const mobileMenu = () => {

    const openBtn = document.querySelector('[data-mobile-menu="open-btn"]');
    const closeBtn = document.querySelector('[data-mobile-menu="close-btn"]');
    const menuBlock = document.querySelector('[data-mobile-menu="block"]');

    //open menu block with animation
    const openMenuLogic = () => {
        menuBlock.classList.add('db');
        setTimeout(() => {
            menuBlock.classList.add('opened');
        }, 1);
    }

    //close menu block with animation
    const closeMenuLogic = () => {
        menuBlock.classList.remove('opened');
        setTimeout(() => {
            menuBlock.classList.remove('db');
        }, 300);
    }

    //open btn logic
    openBtn.addEventListener('click', openMenuLogic);

    // close btn logic
    closeBtn.addEventListener('click', closeMenuLogic);

    window.addEventListener('click', (e) => {
        if (!e.target.closest('[data-mobile-menu="block"]') && !e.target.closest('[data-mobile-menu="open-btn"]')) {
            closeMenuLogic();
        }
    });

}

export {mobileMenu}