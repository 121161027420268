const headerProfileModal = () => {

    const profileBtn = document.querySelector('[data-profile-btn]');
    const profileModal = document.querySelector('[data-profile-modal]');

    //check for elemts is in the template
    if (profileBtn && profileModal) {
        //close profile modal
        const closeModalLogic = () => {
            profileModal.classList.remove('opened');
            setTimeout(() => {
                profileModal.classList.remove('db');
            }, 300);
        }

        //open profile modal
        const openModalLogic = () => {
            profileModal.classList.add('db');
            setTimeout(() => {
                profileModal.classList.add('opened');
            }, 1);
        }

        //profile btn click logic
        profileBtn.addEventListener('click', () => {
            if (!profileModal.classList.contains('db')) {
                openModalLogic();
            } else {
                closeModalLogic();
            }
        });

        window.addEventListener('click', (e) => {
            if (!e.target.closest('[data-profile-modal]') && !e.target.closest('[data-profile-btn]')) {
                closeModalLogic();
            }
        });
    }

}

export {headerProfileModal}