const textChanger = (textBlock, text) => {

    let textNumber = 0;

    setInterval(() => {
        textBlock.classList.add('hidden');
        if (textNumber == 0) {
            setTimeout(() => {
                text.innerHTML = "company";
                textBlock.classList.remove('hidden');
            }, 500);
            return textNumber++;
        } else if (textNumber == 1) {
            setTimeout(() => {
                text.innerHTML = "situation";
                textBlock.classList.remove('hidden');
            }, 500);
            return textNumber++;
        } else if (textNumber == 2) {
            setTimeout(() => {
                text.innerHTML = "person";
                textBlock.classList.remove('hidden');
            }, 500);
            return textNumber = 0;
        }
    }, 3000);

}

export {textChanger}