const bookmarkPlugin = (form, privateForm, items) => {

    let bookmarkItemID, pressedItemBtnBookmark, bookmarkItemType;

    //btns logic
    for (const item of items) {
        const itemBtn = item.querySelector('[data-bookmark-id]');
        const itemId = itemBtn.getAttribute('data-bookmark-id');
        
        if (item.getAttribute('data-bookmark-item-private')) {
            const privateItemBtn = item.querySelectorAll('[data-bookmark-id]')[1];

            privateItemBtn.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                //open/close send btn
                const privateBtn = item.querySelector('[data-private-btn]');
                privateBtn.classList.toggle('opened');
                //send btn logic
                const privateSendBtn = item.querySelector('[data-send-private-btn]');
                const svg = item.querySelector('[data-loadmore-stories-svg]');
                privateSendBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    //button transformation
                    svg.classList.remove('dn');
                    privateSendBtn.classList.add('active');

                    bookmarkItemID = itemId;
                    pressedItemBtnBookmark = privateItemBtn.querySelector('[data-bookmark]');
                    return bookmarkItemID, pressedItemBtnBookmark, privateForm.dispatchEvent(new Event("submit"));
                });
            });
        } else {
            itemBtn.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                //if event button == button from items list, then get the id to constant and stop the cycle
                if (e.target.closest('[data-bookmark-id]').getAttribute('data-bookmark-id') == itemId) {
                    bookmarkItemID = itemId;
                    bookmarkItemType = e.target.closest('[data-bookmark-id]').getAttribute('data-bookmark-type');
                    pressedItemBtnBookmark = itemBtn.querySelector('[data-bookmark]');
                }

                return bookmarkItemID, pressedItemBtnBookmark, form.dispatchEvent(new Event("submit"));
            });
        }
    }

    //form logic
    form.addEventListener('submit', async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (bookmarkItemID) {
            //Creating FormData object
            const fData = new FormData(form);
            //get bookmark cart id and add it to form data
            fData.append('bookmarkItemID', bookmarkItemID);
            fData.append('type', bookmarkItemType);
            const fAction = form.getAttribute('action');

            console.log(bookmarkItemID);
            console.log(bookmarkItemType);

            //Sending form to server
            const fetchResponse = await fetch(fAction, {
                method: 'POST',
                body: fData
            });

            const result = await fetchResponse.json();

            if (fetchResponse.ok) {
                console.log(result);
                if (result == "added") {
                    pressedItemBtnBookmark.parentElement.classList.add('active');
                } else {
                    pressedItemBtnBookmark.parentElement.classList.remove('active');
                }
            } else {
                console.log(result);
            }
        }
    
    });

    //private form logic
    privateForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (bookmarkItemID) {
            //Creating FormData object
            const fData = new FormData(privateForm);
            fData.append('bookmarkItemID', bookmarkItemID);
            const fAction = privateForm.getAttribute('action');

            // privateForm.submit();
            //Sending form to server
            const fetchResponse = await fetch(fAction, {
                method: 'POST',
                body: fData
            });

            const result = await fetchResponse.json();

            if (fetchResponse.ok) {
                console.log(result);

                const svg = document.querySelectorAll(`[data-bookmark-id="${result.bookmarkItemID}"]`)[1];
                const btn = svg.querySelector('[data-send-private-btn]');

                // change loading svg to text
                svg.classList.add('dn');
                btn.classList.remove('active');
                btn.querySelector('.text').innerHTML = "request has been sent";
            } else {
                console.log(result);
            }
        }
    
    });

    //close all modals if clicked on others elements
    window.addEventListener('click', (e) => {
        const privateBtns = document.querySelectorAll('[data-private-btn]');
        for (const privateBtn of privateBtns) {
            if (!e.target.classList.contains('private')) {
                privateBtn.classList.remove('opened');
            }
        }
    });

}

export {bookmarkPlugin}