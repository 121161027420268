const topicBtn = (btn) => {

    if (btn) {
        const list = btn.querySelector('[data-topic-list]');

        //close list logic
        const closeList = () => {
            //change btn color to default
            btn.classList.remove('active');

            list.classList.remove('opened');
            setTimeout(() => {
                list.classList.remove('db');
            }, 300);
        }

        //open list logic
        const openList = () => {
            //change btn color to dark
            btn.classList.add('active');

            list.classList.add('db');
            setTimeout(() => {
                list.classList.add('opened');
            }, 1);
        }

        //list btn logic
        btn.addEventListener('click', () => {
            if (!list.classList.contains('db')) {
                openList();
            } else {
                closeList();
            }
        });

        //close list if the click was not on him
        window.addEventListener('click', (e) => {
            if (!e.target.classList.contains('topic')) {
                closeList();
            }
        });
    }

}

export {topicBtn}