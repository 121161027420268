import {moreBtn} from './modules/moreBtn';
import {languageList} from './modules/languageList';
import {topicBtn} from './modules/topicBtn';
import {tagsButton} from './modules/tagsButton';
import {headerProfileModal} from './modules/headerProfileModal';
import {headerSearch} from './modules/headerSearch';
import {loadmorePlugin} from './modules/loadmorePlugin';
import {bookmarkPlugin} from './modules/bookmarkPlugin';
import {topicBlock} from './modules/topicBlock';
import {cookiesModal} from './modules/cookiesModal';
import {mobileMenu} from './modules/mobileMenu';
import {infoBlock} from './modules/infoBlock';
import {textChanger} from './modules/textChanger';
import {locationList} from './modules/locationList';
// import {playButton} from "./modules/playButton";

//more btn variables
const moreBtnElement = document.querySelector('[data-more-btn]');

//header language list variables
//pc
const headerLanguageList = document.querySelector('[data-language-header-list]');
const headerLanguageBtn = document.querySelector('[data-language-header-btn]');
//mobile
const headerLanguageMobileList = document.querySelector('[data-language-header-mobile-list]');
const headerLanguageMobileBtn = document.querySelector('[data-language-header-mobile-btn]');

//header topic btn variables
const topicButton = document.querySelector('[data-topic-btn]');

//header tags variables
const tagsBtn = document.querySelector('[data-tags-container]');

//faces section loadmore variables
const loadmoreTopForm = document.getElementById('loadmore-top-form');
const loadmoreTopBtn = document.querySelector('[data-loadmore-top-btn]');
const loadmoreTopSvg = document.querySelector('[data-loadmore-top-svg]');
const loadmoreTopWrapper = document.getElementById('section-faces-container');
//popular section loadmore variables
const loadmoreRegionForm = document.getElementById('loadmore-region-form');
const loadmoreRegionBtn = document.querySelector('[data-loadmore-region-btn]');
const loadmoreRegionSvg = document.querySelector('[data-loadmore-region-svg]');
const loadmoreRegionWrapper = document.querySelector(".section-region__container");
//stories section loadmore variables
const loadmoreStoriesForm = document.getElementById('loadmore-stories-form');
const loadmoreStoriesBtn = document.querySelector('[data-loadmore-stories-btn]');
const loadmoreStoriesSvg = document.querySelector('[data-loadmore-stories-svg]');
const loadmoreStorieWrapper = document.getElementById("stories-grid");
//companies section loadmore variables
const loadmoreCompaniesForm = document.getElementById('loadmore-companies-form');
const loadmoreCompaniesBtn = document.querySelector('[data-loadmore-companies-btn]');
const loadmoreCompaniesSvg = document.querySelector('[data-loadmore-companies-svg]');
const loadmoreCompaniesWrapper = document.querySelector("[data-companies-container]");
//questions section loadmore variables
const loadmoreQuestionsForm = document.getElementById('loadmore-questions-form');
const loadmoreQuestionsBtn = document.querySelector('[data-loadmore-questions-btn]');
const loadmoreQuestionsSvg = document.querySelector('[data-loadmore-questions-svg]');
const loadmoreQuestionsWrapper = document.getElementById("questions-grid");

//page bookmark variables
const bookmarkForm = document.getElementById('bookmark-form');
const bookmarkItems = document.querySelectorAll('[data-bookmark-item]');
const privatebookmarkForm = document.getElementById('private-bookmark-form');

//cookies modal variables
const cookiesModalBlock = document.querySelector('[data-cookies-modal]');

//info block
const iBlock = document.getElementById('info-block');

//location lists elements
const sections = document.querySelectorAll('section');

setTimeout(() => {
    const successModal = document.querySelector("[data-success-modal]");

    if (successModal) {
        successModal.addEventListener("click", () => {
            successModal.classList.remove("active");
            setTimeout(() => {
                successModal.classList.add("dn");
            }, 300);
        });
    }
}, 1100);

//async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {
    //UI modules
    moreBtn(moreBtnElement);
    languageList(headerLanguageList, headerLanguageBtn);
    languageList(headerLanguageMobileList, headerLanguageMobileBtn);
    topicBtn(topicButton);
    tagsButton(tagsBtn);
    headerProfileModal();
    headerSearch();
    loadmorePlugin(loadmoreTopForm, loadmoreTopBtn, loadmoreTopSvg, loadmoreTopWrapper);
    loadmorePlugin(loadmoreRegionForm, loadmoreRegionBtn, loadmoreRegionSvg, loadmoreRegionWrapper);
    loadmorePlugin(loadmoreStoriesForm, loadmoreStoriesBtn, loadmoreStoriesSvg, loadmoreStorieWrapper);
    loadmorePlugin(loadmoreCompaniesForm, loadmoreCompaniesBtn, loadmoreCompaniesSvg, loadmoreCompaniesWrapper);
    loadmorePlugin(loadmoreQuestionsForm, loadmoreQuestionsBtn, loadmoreQuestionsSvg, loadmoreQuestionsWrapper);
    bookmarkPlugin(bookmarkForm, privatebookmarkForm, bookmarkItems);
    topicBlock();
    cookiesModal(cookiesModalBlock);
    mobileMenu();
    //if user is logged
    if (iBlock) {
        //elements for text changer
        const textBlock = document.querySelector('[data-text-changer]');
        const text = textBlock.querySelector('[data-text-changer-text]');
        //functions
        infoBlock();
        textChanger(textBlock, text);
    }
    locationList(sections);
    // playButton();
});